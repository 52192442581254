import i18n from "i18next";
import { en } from "./en_US";
import { sc } from "./zh_CN";
import { tc } from "./zh_TW";
import { jp } from "./ja_JP";
import { kr } from "./ko_KR";
import { initReactI18next } from "react-i18next";
//useTranslation
let lng = "ja-JP";
const lang = window.location.href.match(/en-US|ja-JP|zh-TW|ko-KR|zh-CN/gi);
if (lang) {
  // console.log("lang",lang);
  lng = lang[0].replace(/-(\w+)/, ($1, $2) => {
    return $1.toLowerCase();
  });
}
i18n.use(initReactI18next).init({
  resources: {
    "en-US": {
      translation: en,
    },
    "zh-CN": {
      translation: sc,
    },
    "ja-JP": {
      translation: jp,
    },
    "ko-KR": {
      translation: kr,
    },
    "zh-TW": {
      translation: tc,
    },
  },
  lng,
  interpolation: {
    escapeValue: false,
  },
});
// console.log(i18n.language,8888)
// const resources = {
//   "en-US": { translation:enUS},
//   "zh-CN": { translation:zhCN}
// };

// let lng = "en-US"
// const lang =window.location.href.match(/zh-CN|en-US/ig)
// if(lang){
//   // console.log("lang",lang);
//   lng=lang[0].replace(/-(\w+)/,($1,$2)=>{
//     return $1.toLowerCase()
//   })
// }
// i18n.init({ resources, lng });

export default i18n;
